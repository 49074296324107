import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Learning" link="http://Subconscious.ai" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Learning about Human behavior, by studying Foundation Models. 
    </ProjectCard>
    <ProjectCard title="Non-Human Rights" link="https://www.nonhumanrights.org/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Working to include AI in Non-Human rights efforts. 
    </ProjectCard>
    <ProjectCard title="Activism" link="https://www.nytimes.com/2021/06/23/magazine/exxon-mobil-engine-no-1-board.html" bg="linear-gradient(to right, #920045 0%, #EEFC21 100%)" mdxType="ProjectCard">
  A better way to align corporations with their externatlities. 
    </ProjectCard>
    <ProjectCard title="Education" link="https://www.huffpost.com/entry/harnessing-school-as-a-se_b_4393187" bg="linear-gradient(to right, #450092 0%, #21FCEE 100%)" mdxType="ProjectCard">
  Technology will transform education operations, however screens do not improve learning.
    </ProjectCard>
    <ProjectCard title="Charity" link="https://www.enduringhearts.org/about-us/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  I serve on the board of Enduring Hearts, a  pediatric heart transplant charity.
    </ProjectCard>
    <ProjectCard title="Team" link="https://youtu.be/v1XFCkaXYps" bg="linear-gradient(to right, #ED1E79 0%, #D4145A 100%)" mdxType="ProjectCard">
  Passionate about using Data for good. 
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      