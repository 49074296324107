import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Data-driven founder + 2x public exits. Super-Dad. WIP-Human.`}</p>
    <p>{`Market Research leader with deep knowledge of applying big data, small data, and AI to transform markets.`}</p>
    <p>{`Previously: CSO @ Engine No. 1, Analytics Lead @ Two Sigma, Offering Director @ IBM Watson Research. Founder, WAHA Technologies, a zero-carbon data center, acquired by CleanSpark (NASDAQ: CLSK). Founder, CleanEdison, acquired by Kaplan, Inc. (NYSE: GHC). Algorithmic trading DNA. `}</p>
    <p>{`BS in Comp Sci `}<a parentName="p" {...{
        "href": "https://www.cs.jhu.edu/"
      }}>{`@JohnsHopkins`}</a>{`, MBA `}<a parentName="p" {...{
        "href": "https://www.stern.nyu.edu/"
      }}>{`@NYUStern`}</a>{`.`}</p>
    <p>{`Passionate about learning, behavior, and experimental design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      